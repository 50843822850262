// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$blt2-name: 'blt2';
$blt2-x: 196px;
$blt2-y: 147px;
$blt2-offset-x: -196px;
$blt2-offset-y: -147px;
$blt2-width: 10px;
$blt2-height: 8px;
$blt2-total-width: 226px;
$blt2-total-height: 187px;
$blt2-image: 'icons/sprite.png';
$blt2: (196px, 147px, -196px, -147px, 10px, 8px, 226px, 187px, 'icons/sprite.png', 'blt2', );
$btn-down2-name: 'btn_down2';
$btn-down2-x: 0px;
$btn-down2-y: 123px;
$btn-down2-offset-x: 0px;
$btn-down2-offset-y: -123px;
$btn-down2-width: 149px;
$btn-down2-height: 40px;
$btn-down2-total-width: 226px;
$btn-down2-total-height: 187px;
$btn-down2-image: 'icons/sprite.png';
$btn-down2: (0px, 123px, 0px, -123px, 149px, 40px, 226px, 187px, 'icons/sprite.png', 'btn_down2', );
$btn-image2-name: 'btn_image2';
$btn-image2-x: 0px;
$btn-image2-y: 0px;
$btn-image2-offset-x: 0px;
$btn-image2-offset-y: 0px;
$btn-image2-width: 226px;
$btn-image2-height: 41px;
$btn-image2-total-width: 226px;
$btn-image2-total-height: 187px;
$btn-image2-image: 'icons/sprite.png';
$btn-image2: (0px, 0px, 0px, 0px, 226px, 41px, 226px, 187px, 'icons/sprite.png', 'btn_image2', );
$btn-msds-name: 'btn_msds';
$btn-msds-x: 0px;
$btn-msds-y: 163px;
$btn-msds-offset-x: 0px;
$btn-msds-offset-y: -163px;
$btn-msds-width: 75px;
$btn-msds-height: 24px;
$btn-msds-total-width: 226px;
$btn-msds-total-height: 187px;
$btn-msds-image: 'icons/sprite.png';
$btn-msds: (0px, 163px, 0px, -163px, 75px, 24px, 226px, 187px, 'icons/sprite.png', 'btn_msds', );
$btn-msds2-name: 'btn_msds2';
$btn-msds2-x: 0px;
$btn-msds2-y: 41px;
$btn-msds2-offset-x: 0px;
$btn-msds2-offset-y: -41px;
$btn-msds2-width: 226px;
$btn-msds2-height: 41px;
$btn-msds2-total-width: 226px;
$btn-msds2-total-height: 187px;
$btn-msds2-image: 'icons/sprite.png';
$btn-msds2: (0px, 41px, 0px, -41px, 226px, 41px, 226px, 187px, 'icons/sprite.png', 'btn_msds2', );
$btn-search-name: 'btn_search';
$btn-search-x: 75px;
$btn-search-y: 163px;
$btn-search-offset-x: -75px;
$btn-search-offset-y: -163px;
$btn-search-width: 22px;
$btn-search-height: 22px;
$btn-search-total-width: 226px;
$btn-search-total-height: 187px;
$btn-search-image: 'icons/sprite.png';
$btn-search: (75px, 163px, -75px, -163px, 22px, 22px, 226px, 187px, 'icons/sprite.png', 'btn_search', );
$btn-tds-name: 'btn_tds';
$btn-tds-x: 149px;
$btn-tds-y: 123px;
$btn-tds-offset-x: -149px;
$btn-tds-offset-y: -123px;
$btn-tds-width: 75px;
$btn-tds-height: 24px;
$btn-tds-total-width: 226px;
$btn-tds-total-height: 187px;
$btn-tds-image: 'icons/sprite.png';
$btn-tds: (149px, 123px, -149px, -123px, 75px, 24px, 226px, 187px, 'icons/sprite.png', 'btn_tds', );
$btn-tds2-name: 'btn_tds2';
$btn-tds2-x: 0px;
$btn-tds2-y: 82px;
$btn-tds2-offset-x: 0px;
$btn-tds2-offset-y: -82px;
$btn-tds2-width: 226px;
$btn-tds2-height: 41px;
$btn-tds2-total-width: 226px;
$btn-tds2-total-height: 187px;
$btn-tds2-image: 'icons/sprite.png';
$btn-tds2: (0px, 82px, 0px, -82px, 226px, 41px, 226px, 187px, 'icons/sprite.png', 'btn_tds2', );
$dot-icon-name: 'dot-icon';
$dot-icon-x: 183px;
$dot-icon-y: 147px;
$dot-icon-offset-x: -183px;
$dot-icon-offset-y: -147px;
$dot-icon-width: 13px;
$dot-icon-height: 12px;
$dot-icon-total-width: 226px;
$dot-icon-total-height: 187px;
$dot-icon-image: 'icons/sprite.png';
$dot-icon: (183px, 147px, -183px, -147px, 13px, 12px, 226px, 187px, 'icons/sprite.png', 'dot-icon', );
$dot-active-icon-name: 'dotActive-icon';
$dot-active-icon-x: 149px;
$dot-active-icon-y: 147px;
$dot-active-icon-offset-x: -149px;
$dot-active-icon-offset-y: -147px;
$dot-active-icon-width: 17px;
$dot-active-icon-height: 16px;
$dot-active-icon-total-width: 226px;
$dot-active-icon-total-height: 187px;
$dot-active-icon-image: 'icons/sprite.png';
$dot-active-icon: (149px, 147px, -149px, -147px, 17px, 16px, 226px, 187px, 'icons/sprite.png', 'dotActive-icon', );
$download-icon-name: 'download-icon';
$download-icon-x: 97px;
$download-icon-y: 163px;
$download-icon-offset-x: -97px;
$download-icon-offset-y: -163px;
$download-icon-width: 22px;
$download-icon-height: 20px;
$download-icon-total-width: 226px;
$download-icon-total-height: 187px;
$download-icon-image: 'icons/sprite.png';
$download-icon: (97px, 163px, -97px, -163px, 22px, 20px, 226px, 187px, 'icons/sprite.png', 'download-icon', );
$search-icon-name: 'search-icon';
$search-icon-x: 166px;
$search-icon-y: 147px;
$search-icon-offset-x: -166px;
$search-icon-offset-y: -147px;
$search-icon-width: 17px;
$search-icon-height: 14px;
$search-icon-total-width: 226px;
$search-icon-total-height: 187px;
$search-icon-image: 'icons/sprite.png';
$search-icon: (166px, 147px, -166px, -147px, 17px, 14px, 226px, 187px, 'icons/sprite.png', 'search-icon', );
$spritesheet-width: 226px;
$spritesheet-height: 187px;
$spritesheet-image: 'icons/sprite.png';
$spritesheet-sprites: ($blt2, $btn-down2, $btn-image2, $btn-msds, $btn-msds2, $btn-search, $btn-tds, $btn-tds2, $dot-icon, $dot-active-icon, $download-icon, $search-icon, );
$spritesheet: (226px, 187px, 'icons/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
