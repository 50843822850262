// Default variables.
@import 'default-variables';

// Bootstrap Framework.
@import '../assets/stylesheets/bootstrap';

// Base-theme overrides.
@import 'overrides';

@import 'sprite';

@import 'fonts';

$MyriadPro: 'MyriadPro-Regular';
$AGroticLt: 'AGroticLt';
$AGroticLtBold: 'AGroticLtBold';
$AGroticLtItalic: 'AGroticLtItalic';

html, body {
  line-height: 1;
  height: 100%;
  width: 100%;
}

.no-scroll {
  overflow: hidden;
}

a, img, input, select, button, textarea, option {
  outline: none;
}

a {
  color: #6c6c6c;
  &:active,
  &:hover {
    color: #905b1b;
  }
}

.wrapper {
  background: #fff;
  .front & {
    background: #2f2f2f url("img/main-bg.jpg") repeat-x 0 0;
  }
  width: 100%;
}
.bg-light {
  .front & {
    background: url("img/bg-light.png") no-repeat center 0;
  }
  height: 100%;
  width: 100%;

}
.page-container {
  margin: 0 auto;
  width: 980px;
}
.page-header {
  background: #b3b5b3 url("img/header-bg.jpg") repeat-y center 0;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.17);
  border: none;
  margin: 0;
  padding-bottom: 0;
  height: 122px;
  width: 100%;
  display: none;
  @include breakpoint($showMenu) {
    display: block;
  }
}
.header-content {
  background: url("img/menu-bg.png") no-repeat 0 0;
  margin: 0 auto;
  padding: 15px 0 0 0;
  width: 980px;
}
.logo {
  margin: 0 0 14px 20px;
}
.region-search {
  float: right;
  margin: 6px 20px 0 0;
  position: relative;
  .form-item, .form-actions {
    margin: 0;
  }
  .form-submit {
    border: none;
    @include sprite($search-icon);
    background-color: transparent;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 6px;
    overflow: hidden;
    text-indent: -100px;
    cursor: pointer;
  }
}
.form-item-custom-search-blocks-form-1,
.form-item-search-block-form {
  .form-text {
    border: none;
    border-radius: 3px;
    background-color: #b3781f;
    box-shadow: 0 1px 0 0 #dabc4b, inset 0 1px 3px 0 #000000;
    padding: 3px 35px 3px 10px;
    color: #ffffff;
    height: 26px;
    width: 204px;
  }
}
.region-header {
  clear: both;
  .menu {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      border-top: 5px solid transparent;
      display: inline-block;
      margin: 0;
      padding: 18px 15px;
      position: relative;
      a {
        font-family: $MyriadPro;
        font-size: 15px;
        color: #000000;
        text-decoration: none;
      }
      &.active-trail,
      &.active,
      &:hover {
        border-top-color: #5f4024;
        a {
          color: #82562a;
        }
        li a {
          color: #fff;
        }
      }
      &:hover .menu {
        opacity: 1;
        visibility: visible;
      }
      .menu {
        background-image: -moz-linear-gradient( -38deg, rgb(119,79,41) 0%, rgb(126,86,43) 17%, rgb(105,71,40) 29%, rgb(135,89,44) 50%, rgb(119,79,41) 68%, rgb(125,83,42) 84%, rgb(93,61,28) 100%);
        background-image: -webkit-linear-gradient( -38deg, rgb(119,79,41) 0%, rgb(126,86,43) 17%, rgb(105,71,40) 29%, rgb(135,89,44) 50%, rgb(119,79,41) 68%, rgb(125,83,42) 84%, rgb(93,61,28) 100%);
        background-image: -ms-linear-gradient( -38deg, rgb(119,79,41) 0%, rgb(126,86,43) 17%, rgb(105,71,40) 29%, rgb(135,89,44) 50%, rgb(119,79,41) 68%, rgb(125,83,42) 84%, rgb(93,61,28) 100%);
        background-image: linear-gradient( -38deg, rgb(119,79,41) 0%, rgb(126,86,43) 17%, rgb(105,71,40) 29%, rgb(135,89,44) 50%, rgb(119,79,41) 68%, rgb(125,83,42) 84%, rgb(93,61,28) 100%);
        position: absolute;
        visibility: hidden;
        opacity: 0;
        left: -45px;
        top: 48px;
        padding: 15px 10px 5px 10px;
        -moz-transition: all .3s ease-in;
        -o-transition: all .3s ease-in;
        -ms-transition: all .3s ease-in;
        transition: all .3s ease-in;
        z-index: 1;
        width: 200px;
      }
      li {
        border: none;
        display: block;
        margin: 0 0 10px 0;
        padding: 0;
        text-align: left;
        &::before {
          content: "•";
          color: #fff;
          margin-right: 7px;
        }
        a {
          color: #fff;
          font-family: 'Arial';
          font-size: 12px;
        }
        &.active-trail,
        &.active,
        &:hover {
          a {
            color: #fff;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.mobile-header {
  background: #CBA337;
  display: block;
  color: #FFFFFF;
  padding: 15px;
  height: 55px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  -webkit-transition: all 0.5s ease-out, background 1s ease-out;
  transition: all 0.5s ease-out, background 1s ease-out;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  &.showing-drawer {
    position: fixed;
    height: 100%;
    -webkit-transition: all 0.3s ease-in, background 0.5s ease-in;
    transition: all 0.3s ease-in, background 0.5s ease-in;
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
  }
  @include breakpoint($showMenu) {
    display: none;
  }
  .logo {
    float: left;
    margin: 0;
    width: 70px;
    img {
      display: inline-block;
      height: auto;
      max-width: 100%;
      width: 100%;
    }
  }
}
.burger-container {
  position: relative;
  float: right;
  outline: none;
  margin: 0;
  height: 25px;
  width: 25px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  .showing-drawer & {
    transform: rotate(90deg);
    .menu-burger {
      .bar {
        transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition-delay: 0.2s;
        &.topBar {
          transform: translateY(7px) rotate(45deg);
          &.second {
            opacity: 0;
          }
        }
        &.btmBar {
          transform: translateY(3px) rotate(-45deg);
        }
      }
    }
  }
}
.menu-burger {
  width: 25px;
  height: 18px;
  position: relative;
  display: block;
  margin: -9px auto 0;
  top: 50%;
  .bar {
    background: #FFFFFF;
    display: block;
    position: relative;
    height: 2px;
    transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0s;
    width: 100%;
    &.topBar {
      transform: translateY(0px) rotate(0deg);
      &.second {
        transform: translateY(6px) rotate(0deg);
      }
    }
    &.btmBar {
      transform: translateY(12px) rotate(0deg);
    }
    .showing-drawer & {
      transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      transition-delay: 0.2s;
      &.topBar {
        transform: translateY(7px) rotate(45deg);
        &.second {
          opacity: 0;
        }
      }
      &.btmBar {
        transform: translateY(3px) rotate(-45deg);
      }
    }
  }
}

.mobile-menu-wrapper {
  display: block;
  clear: both;
  float: left;
  margin: 40px 0 0 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  ul.mobile-menu {
    display: block;
    margin: 0;
    padding: 0 30px;
    height: 400px;
  }
  ul.mobile-menu li {
    display: block;
    border-bottom: 1px solid #ccc;
    margin-top: 5px;
    padding: 0;
    -webkit-transform: scale(1.15) translateY(-30px);
    transform: scale(1.15) translateY(-30px);
    opacity: 0;
    -webkit-transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        -webkit-transition-delay: #{0.56 - ($i * 0.07)}s;
        transition-delay: #{0.56 - ($i * 0.07)}s;
      }
    }
    &:last-child {
      border-bottom: none;
    }
    .showing-drawer & {
      -webkit-transform: scale(1) translateY(0px);
      transform: scale(1) translateY(0px);
      opacity: 1;
      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          -webkit-transition-delay: 0.27s;
          transition-delay: 0.27s;
        }
      }
    }
    a {
      display: block;
      position: relative;
      color: #FFF;
      text-decoration: none;
      font-size: 22px;
      line-height: 2.35;
      font-family: $AGroticLt;
      font-weight: 200;
      width: 100%;
    }
  }
}

.main-container {
  min-height: calc(100vh - 287px);
  padding-bottom: 50px;
  padding-top: 55px;
  clear: both;
  @include breakpoint($showMenu) {
    padding-top: 0;
  }
  @include breakpoint($mediumScreen) {
    padding-left: 0;
    padding-right: 0;
  }
}

.footer-before-bg {
  background: #2f2f2f;
}
.region-before-footer {
  padding: 50px 15px 0 15px;
  overflow: hidden;
  @include breakpoint($mediumScreen) {
    padding: 20px 0 50px 0;
  }
  .block {
    margin-bottom: 50px;
    @include breakpoint($mediumScreen) {
      float: left;
      margin-right: 45px;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .title {
    font-family: $AGroticLt;
    font-size: 15px;
    color: #fff;
    margin: 0 0 13px 0;
    padding: 0;
  }
  .content > .menu {
    margin: 0;
    padding: 0;
  }
  .menu > .leaf {
    margin: 0 0 13px 0;
    padding: 0;
    &:last-child {
      margin: 0;
    }
    &.active-trail,
    &.active,
    &:hover {
      a {
        text-decoration: underline;
      }
    }
    a {
      color: #fff;
      font-family: $AGroticLt;
      font-size: 15px;
      text-decoration: none;
    }
  }
}

.footer {
  padding: 0;

  overflow: hidden;
  @include breakpoint($mediumScreen) {
    height: 287px;
  }
}
.footer-bg {
  background: #444444;
}
.region-footer {
  padding: 25px 0;
  overflow: hidden;
  color: #888888;
  a {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
  .soil-footer-block {
    text-align: center;
    margin: 10px 0;
    @include breakpoint($mediumScreen) {
      text-align: left;
      margin: 0;
      &.footer-copyrighting {
        float: left;
      }
      &.footer-info {
        float: right;
      }
    }
  }
}

// Content TABS
.view-references-node {
  margin-top: 40px;
}
.quicktabs-wrapper {
  .quicktabs-tabs {
    border-bottom: 2px solid #956839;
    float: left;
    margin: 0 0 40px 0;
    width: 100%;
    li {
      border: 2px solid transparent;
      border-bottom: none;
      float: left;
      margin: 0;
      padding: 0;
      white-space: unset;
      text-align: center;
      position: relative;
      top: 2px;
      width: 200px;
      a {
        display: inline-block;
        padding: 10px 20px;
        font-family: $AGroticLt;
        font-size: 14px;
        text-decoration: none;
      }
      &:hover,
      &.active {
        border-color: #956839;
        background: #fff;
      }
    }
  }
  .quicktabs_main {
    clear: both;
  }
}


// Content
.quicktabs-tabpage,
.node {
  line-height: 1.4;
  ul,ol {
    padding: 0 0 0 15px;
    margin: 0;
    li {
      list-style: disc;
      margin: 0 0 5px 0;
      ul,ol {
        margin-top: 5px;
        li {
          list-style: square;
        }
      }
    }
  }
  table {
    border-bottom: 1px solid #222;
    border-top: 2px solid #000;
    text-align: center;
    table-layout: fixed;
    width: 100%;
    th {
      border-bottom: 1px solid #222222;
      color: #222;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      padding: 10px 0;
      font-family: $AGroticLtBold;
    }
    td {
      border-bottom: 1px solid #e5e5e5;
      padding: 10px 0;
      font-family: $AGroticLt;
    }
  }
}

.page-title {
  text-transform: uppercase;
  margin: 20px 0;
}

.media, .media-body {
  overflow: scroll;
  @include breakpoint($smallScreen) {
    overflow: hidden;
  }
}

// Product
.node-product {
  .field-label {
    color: #222;
    font-size: 18px;
    line-height: 1.1;
    margin: 0 0 10px -15px;
    &::before {
      display: inline-block;
      @include sprite($blt2);
      content: "";
      margin: 0 5px 2px 0;
      width: 9px;
    }
  }
  .field-label-above {
    padding: 0 0 40px 15px;
  }
  .right-side {
    text-align: center;
    @include breakpoint($mediumScreen) {
      text-align: right;
    }
  }

  &.node-teaser {
    border: 1px solid #e5e5e5;
    padding: 20px 15px;
    //margin: 0 0 -1px 0;
    margin: 0;
    @include breakpoint($extraSmallScreen) {
      padding: 20px 15px 20px 192px;
    }
    @include breakpoint($mediumScreen) {
      &.col-lg-6,
      &.col-md-6 {
        &:nth-child(2n+1) {
          //border-left: none;
        }
      }
    }
    @include breakpoint($mobileLandscape) {
      height: 250px;
    }
  }
  .field-name-field-product-simage {
    text-align: center;
    @include breakpoint($extraSmallScreen) {
      position: absolute;
      top: 20px;
      left: 0;
      width: 192px;
    }
  }
  .field-name-field-product-subtitle {
    font-weight: bold;
    margin-bottom: 10px;
    overflow: hidden;
    @include breakpoint($mobileLandscape) {
      height: 20px;
      word-break: keep-all;
    }
  }
  .field-name-field-product-shortdesc {
    overflow: hidden;
    line-height: 1.5;
    @include breakpoint($mobileLandscape) {
      word-break: keep-all;
      height: 105px;
    }
  }
  .product-links {
    vertical-align: top;
    margin: 10px 0 0 0;
  }
  .product-more {
    display: inline-block;
    overflow: hidden;
    background: #905b1b;
    color: #FFFFFF;
    text-align: center;
    font-size: 10px;
    padding: 5px 0;
    width: 75px;
  }
}

.teaser-title {
  margin: 0 0 10px 0;
  a {
    font-size: 20px;
    line-height: 1.2;
    color: #222222;
    text-decoration: none;
    &:hover {
      color: #898989;
    }
  }
}

.field-name-field-page-image,
.field-name-field-category-image,
.field-name-field-product-image {
  margin-bottom: 30px;
  display: none;
  @include breakpoint($mobileLandscape) {
    display: block;
  }
  img {
    display: inline-block;
    height: auto;
    max-width: 100%;
    width: 100%;
  }
}
.product-download {
  display: inline-block;
  @include sprite($btn-msds2);
  text-indent: -200px;
  font-size: 1px;
  overflow: hidden;
  &.icon-tds {
    @include sprite-image($btn-tds2);
    @include sprite-position($btn-tds2);
  }
  &.icon-image {
    @include sprite-image($btn-image2);
    @include sprite-position($btn-image2);
  }

  .node-teaser & {
    @include sprite($btn-msds);
    &.icon-tds {
      @include sprite-image($btn-tds);
      @include sprite-position($btn-tds);
    }
  }
}

// Adv
.node-pr-advertistmant {
  &.node-teaser {
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: -1px;
    padding: 20px 15px;
    text-align: center;
    .views-row-last & {
      border-bottom: none;
      margin-bottom: 0;
    }
    @include breakpoint($smallScreen) {
      height: 290px;
      .views-row-last & {
        border-bottom: 1px solid #c0c0c0;
        margin-bottom: -1px;
      }
      .teaser-title {
        height: 26px;
        overflow: hidden;
      }
      .field-name-body {
        height: 20px;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
}
.field-name-field-pr-video {
  margin-bottom: 10px;
  //@include breakpoint($smallScreen) {
  //  margin-bottom: 0;
  //}
}

.view-front {
  .views-row {
    &:nth-child(3n+1) {
      .node-front-block {
        //border-left: 0;
      }
    }
  }
}
.node-front-block {
  border: 1px solid #787d7d;
  background: #ffffff;
  position: relative;
  overflow: hidden;
  padding: 53px 10px 10px 10px;
  margin-bottom: -1px;
  @include breakpoint($extraSmallScreen) {
    height: 221px;
    padding: 53px 5px 5px 100px;
    margin-right: -1px;
  }
  .teaser-title {
    position: absolute;
    left: 20px;
    top: 15px;
    z-index: 1;
    font-size: 20px;
    line-height: 1.2;
    color: #222;
    margin: 0;
  }
  .field-name-body {
    overflow: hidden;
    line-height: 1.5;
    @include breakpoint($mobileLandscape) {
      word-break: keep-all;
      height: 105px;
    }
  }
  &.node-30 {
    background: #e5e6e5;
  }
  &.dtype-0 {
    position: relative;
    a {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
    }
    @include breakpoint($largeScreen) {
      position: relative;
      top: 0;
      transform: none;
      height: 74px;
    }
  }
  &.dtype-2 .field-name-body {
    position: absolute;
    top: 53px;
    left: 20px;
    a {
      display: block;
      @include sprite-image($download-icon);
      @include sprite-position($download-icon);
      padding-left: 30px;
      margin-top: -1px;
    }
  }

  &.dtype-0,
  &.dtype-1,
  &.dtype-2 {
    padding: 0;
    img {
      display: inline-block;
      height: auto;
      max-width: 100%;
      width: 100%;
      @include breakpoint($largeScreen) {
        max-width: unset;
        width: auto;
      }
    }
  }
  a {
    color: #333333;
    text-decoration: none;
  }
}
.field-name-field-front-image {
  @include breakpoint($largeScreen) {
    .node-front-block.dtype-1 & {
      img {
        height: 221px;
        width: 325px;
      }
    }
  }
  .node-front-block.dtype-3 & {
    text-align: center;
    @include breakpoint($extraSmallScreen) {
      position: absolute;
      left: 0;
      top: 53px;
    }
  }
}
.field-name-field-front-subtitle {
  font-weight: 700;
  margin-bottom: 10px;
  overflow: hidden;
  @include breakpoint($mobileLandscape) {
    height: 20px;
    word-break: keep-all;
  }
}

.prevnext-wrapper {
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  overflow: hidden;
  padding: 10px;
  margin: 20px 0 0 0;
  .prevnext-previous {
    float: left;
  }
  .prevnext-next {
    float: right;
  }
  a {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #424242;
    &:active,
    &:hover {
      color: #905b1b;
    }
  }
}

.view {
  table {
    border-bottom: 1px solid #222;
    border-top: 2px solid #000;
    text-align: left;
    table-layout: fixed;
    width: 100%;
    th {
      border-bottom: 1px solid #222222;
      color: #6c6c6c;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      padding: 10px 0;
      font-family: $AGroticLtBold;
      &.views-field-counter {
        text-align: center;
        width: 15%;
      }
    }
    td {
      border-bottom: 1px solid #e5e5e5;
      padding: 10px 0;
      font-family: $AGroticLt;
      color: #6c6c6c;
      &.views-field-counter {
        text-align: center;
      }

    }
  }

  .pager {
    margin: 20px 0 0 0;
    li {
      margin: 0;
    }
  }
}

.view-downloads {
  .views-field-title {
    width: 60%;
  }
  .views-field-nothing {
    .file-icon,
    .file-size {
      display: none;
    }
    .download-msds a {
      display: inline-block;
      @include sprite($btn-msds);
      text-indent: -200px;
      font-size: 1px;
      overflow: hidden;
    }
    .download-tds a {
      display: inline-block;
      @include sprite($btn-tds);
      text-indent: -200px;
      font-size: 1px;
      overflow: hidden;
    }
  }

  .views-exposed-widgets {
    position: relative;
    margin-bottom: 20px;
  }
  .views-exposed-widget {
    padding: 0;
    float: right;
    .form-text {
      border: 1px solid #e5e5e5;
      background: #fff;
      line-height: 18px;
      height: 30px;
      padding: 5px 30px 5px 5px;
      margin: 0;
      -webkit-appearance: none;
      appearance: none;
      width: 210px;
    }
    .form-submit {
      border: none;
      @include sprite($btn-search);
      margin: 0;
      position: absolute;
      top: 4px;
      right: 4px;
      padding: 0;
      font-size: 1px;
      text-indent: -200px;
    }
  }
}

.region-content-top {
  display: none;
  clear: both;
  overflow: hidden;
  width: 100%;
  @include breakpoint($showMenu) {
    display: block;
  }
}

.slick-slide {
  outline: none;
  overflow: hidden;
  text-align: center;
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
}
.slick-slider {
  margin: 0;
}

.slick-dots {
  bottom: 25px;
  li {
    background: #905b1b;
    border: 2px solid #905b1b;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    height: 18px;
    width: 18px;
    button{
      padding: 0;
      height: 15px;
      width: 15px;
      &:before {
        font-size: 6px;
        line-height: 15px;
        opacity: 1;
        color: #905b1b;
        height: 15px;
        width: 13px;
      }
    }
    &.slick-active {
      background: none;
      button:before {
        opacity: 1;
        color: #905b1b;
      }
    }
  }
}