@font-face {
  font-family: 'MyriadPro-Regular';
  src: url('fonts/MyriadPro/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/MyriadPro/MyriadPro-Regular.otf')  format('opentype'),
  url('fonts/MyriadPro/MyriadPro-Regular.woff') format('woff'),
  url('fonts/MyriadPro/MyriadPro-Regular.ttf')  format('truetype'),
  url('fonts/MyriadPro/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AGroticLt';
  src: url('fonts/AGroticLt/AGroticLt.eot?#iefix') format('embedded-opentype'),
  url('fonts/AGroticLt/AGroticLt.woff') format('woff'),
  url('fonts/AGroticLt/AGroticLt.ttf')  format('truetype'),
  url('fonts/AGroticLt/AGroticLt.svg#AGroticLt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AGroticLtBold';
  src: url('fonts/AGroticLtBold/AGroticLtBold.eot?#iefix') format('embedded-opentype'),
  url('fonts/AGroticLtBold/AGroticLtBold.woff') format('woff'),
  url('fonts/AGroticLtBold/AGroticLtBold.ttf')  format('truetype'),
  url('fonts/AGroticLtBold/AGroticLtBold.svg#AGroticLtBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AGroticLtItalic';
  src: url('fonts/AGroticLtItalic/AGroticLtItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/AGroticLtItalic/AGroticLtItalic.woff') format('woff'),
  url('fonts/AGroticLtItalic/AGroticLtItalic.ttf')  format('truetype'),
  url('fonts/AGroticLtItalic/AGroticLtItalic.svg#AGroticLtItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}